export enum Status {
    Active = 'Active',
    Pending = 'Pending',
    Banned = 'Banned',
}

export enum StatusCalculated {
    Active = 'Active',
    Pending = 'Pending',
    Banned = 'Banned',
    NonActive = 'NonActive',
    Reactivated = 'Reactivated',
}

export interface Option<T> {
    [key: string]: string | T | undefined
    text: string
    value: T
}

export interface CheckboxOption<T> extends Option<T> {
    textColor?: string
}

export type StatusesColors = Record<keyof typeof Status, string>


export const statusesColors: StatusesColors = {
    [Status.Active]: 'var(--freedom-120)',
    [Status.Pending]: 'var(--calendula-100)',
    [Status.Banned]: 'var(--poppy-120)'
} as const

export const statusIconsColors: StatusesColors = {
    [Status.Active]: '#16c60c',
    [Status.Pending]: '#fff100',
    [Status.Banned]: '#e81224',
} as const


export type StatusesCalculatedColors = Record<keyof typeof StatusCalculated, string>

export const statusesCalculatedColors: StatusesCalculatedColors = {
    [StatusCalculated.Active]: 'var(--freedom-120)',
    [StatusCalculated.Pending]: 'var(--calendula-100)',
    [StatusCalculated.Banned]: 'var(--poppy-120)',
    [StatusCalculated.NonActive]: 'var(--day-40)',
    [StatusCalculated.Reactivated]: 'var(--seoul-100)',
} as const

export const statusCalculatedIconsColors: StatusesCalculatedColors = {
    [StatusCalculated.Active]: '#16c60c',
    [StatusCalculated.Pending]: '#fff100',
    [StatusCalculated.Banned]: '#e81224',
    [StatusCalculated.NonActive]: '#999EAD',
    [StatusCalculated.Reactivated]: '#A868FF'
} as const

export type LevelCalculatedColors = Record<number, string>

export const levelCalculatedColors: LevelCalculatedColors = {
    [1]: 'var(--poppy-120)',
    [2]: 'var(--day-100)',
    [3]: 'var(--day-100)',
    [4]: 'var(--day-100)',
    [5]: 'var(--day-100)',
    [6]: 'var(--day-100)'
} as const

export type DateRange = [start: string, end: string]