import {
    liveBalanceMeta,
    prideBalanceMeta,
    MetaScopeName,
    MetaData,
    joolaMeta
} from '@/helpers/meta'

import { DeviceOs } from '@/helpers/device'

export type AdminPanelBrandName = 'livebalance' | 'pridebalance' | 'joola'

type BrandInfo = {
    packageName: string
    brandId: number
    brandName: AdminPanelBrandName
    hostnames: string[]
    logo: string
    title: string
    metaData: Record<MetaScopeName, MetaData>
    presetOs?: DeviceOs
}

const defaultHostnames: string[] = [
    'devagency.livebalance.biz',
    'qaagency.livebalance.biz',
    'agency.livebalance.biz',
    'livebalance.biz'
]

const prideHostnames: string[] = [
    'devpride.livebalance.biz',
    'qapride.livebalance.biz',
    'pride.livebalance.biz'
]

const joolaHostnames: string[] = [
    'devjoola.livebalance.biz',
    'qajoola.livebalance.biz',
    'joola.livebalance.biz'
]

const OLIVE_BRAND_ID = 1
const TOPLIVE_BRAND_ID = 8

const androidBrands: BrandInfo[] = [
    {
        packageName: 'com.videochat.olive',
        brandId: OLIVE_BRAND_ID,
        brandName: 'livebalance',
        hostnames: defaultHostnames,
        logo: 'logo',
        title: 'Live Balance',
        metaData: liveBalanceMeta,
        presetOs: DeviceOs.Android
    },
    {
        packageName: 'com.callmechat',
        brandId: 6,
        brandName: 'livebalance',
        hostnames: defaultHostnames,
        logo: 'logo',
        title: 'Live Balance',
        metaData: liveBalanceMeta,
        presetOs: DeviceOs.Android
    }
]

const IOSBrands: BrandInfo[] = [
    {
        packageName: 'com.toplive.chat',
        brandId: TOPLIVE_BRAND_ID,
        brandName: 'livebalance',
        hostnames: defaultHostnames,
        logo: 'logo',
        title: 'Live Balance',
        metaData: liveBalanceMeta,
        presetOs: DeviceOs.IOS
    }
]

const mobileBrands: BrandInfo[] = [...androidBrands, ...IOSBrands]

const webBrands: BrandInfo[] = [
    {
        packageName: 'com.lowins.chat',
        brandId: 7,
        brandName: 'pridebalance',
        hostnames: prideHostnames,
        logo: 'logo-pride',
        title: 'Pride Balance',
        metaData: prideBalanceMeta
    },
    {
        packageName: 'co.joola',
        brandId: 10,
        brandName: 'joola',
        hostnames: joolaHostnames,
        logo: 'logo-joola',
        title: 'Joola',
        metaData: joolaMeta
    }
]

export const brands: BrandInfo[] = [...mobileBrands, ...webBrands]

export function getBrandInfoByBrandId(brandId: number): BrandInfo | undefined {
    return brands.find((brandInfo) => brandInfo.brandId === brandId)
}

function getCurrentBrand(): BrandInfo {
    let brandInfo = brands.find((brandInfo) =>
        brandInfo.hostnames.includes(location.hostname)
    )

    if (!brandInfo && localStorage.brand) {
        brandInfo = getBrandInfoByBrandId(Number(localStorage.brand))
    }

    return brandInfo || brands[0]
}

export const currentBrand = getCurrentBrand()

export const isPrideBalance = currentBrand.brandName === 'pridebalance'
export const isLiveBalance = currentBrand.brandName === 'livebalance'
export const isJoolaBalance = currentBrand.brandName === 'joola'
