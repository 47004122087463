import { Module } from 'vuex'
import { RootState } from '@/store'
import { axiosCore, axiosAnalytics, axiosAdminBack } from '@/plugins/axios'
import { PenaltiesData } from '@/interfaces/penalty'
import { InviteItem, InvitationLink, StreamersActivities, StreamerActivity, DayActivity } from '@/interfaces/streamer'
import { LogItem } from '@/interfaces/log'
import { EarningsByDate, EarningsDataWithDate } from '@/interfaces/earnings'
import { StreamersLevelData, UserLevelInfo, KPIConfigInfo } from '@/interfaces/levels'
import { formatDate } from '@/helpers/date'
import { sortByDateField } from '@/helpers/data'
import { calculateStreamerStatus } from '@/helpers/status'

export interface StreamersState {
    invites: InviteItem[]
    invitesMap: { [key: string]: InviteItem }
    invitationLink: InvitationLink | null
    streamersActivities: StreamersActivities
    penaltiesData: PenaltiesData | null
    levelInfo: UserLevelInfo | null,
    kpiConfigInfo: KPIConfigInfo | null,
    balance: number,
    warningStatistic: LogItem[]
    currentStreamerEarnings: EarningsDataWithDate[],
    // can be used to inform about "loading" request state
    // for example use to show loader until data received
    loadingQueriesStatus: { [key: string]: boolean }
    streamersLevelsInfo: StreamersLevelData[]
}

function checkIsStreamerActive(dayActivity: DayActivity): boolean {
    const activeStreamerActivityFields: Array<keyof DayActivity> = [
        'coinsTotal',
        'totalPenalty',
        'bonus',
        'bonusPenalty',
        'thisPeriod',
        'lastPeriodTotal',
        'redeemablePoints',
        'streamersIncome',
        'agencyCommission',
        'agencyTotalRevenue',
        'first_launch',
        'matching',
        'warning',
        'penalty',
        'penaltyDown',
    ]

    return activeStreamerActivityFields.some(fieldName => dayActivity[fieldName])
}

function activeStreamerActivityFields(dayActivity: DayActivity): DayActivity {
    if (checkIsStreamerActive(dayActivity)) {
        dayActivity.activeStreamers = 1
    }

    return dayActivity
}

export const streamersStore: Module<StreamersState, RootState> = {
    state: {
        invites: [],
        invitesMap: {},
        invitationLink: null,
        streamersActivities: {},
        penaltiesData: null,
        levelInfo: null,
        kpiConfigInfo: null,
        balance: 0,
        warningStatistic: [],
        currentStreamerEarnings: [],
        loadingQueriesStatus: {},
        streamersLevelsInfo: []
    },
    getters: {
        invites: state => state.invites.map((invite: InviteItem) => {
            invite.statusCalculated = calculateStreamerStatus(invite, state.streamersActivities[invite.userId]?.days)
            return invite
        })
        ,
        invitesMap: state => state.invitesMap,
        invitationLink: state => state.invitationLink,
        streamersActivities: state => state.streamersActivities,
        penaltiesData: state => state.penaltiesData,
        penaltyLevel: state => {
            if (state.penaltiesData?.history.length === 0) {
                return 0
            }

            return (state.penaltiesData?.curentPenaltyLevel || 0)
        },
        levelInfo: state => state.levelInfo,
        balance: state => state.balance,
        warningStatistic: state => state.warningStatistic,
        currentStreamerEarnings: state => state.currentStreamerEarnings,
        isLoadingStreamersActivities: state => 'loadStreamersActivities' in state.loadingQueriesStatus,
        streamersLevelsInfo: state => state.streamersLevelsInfo,
        kpiConfigInfo: state => state.kpiConfigInfo,
    },
    mutations: {
        setPenaltiesData(state, data) {
            state.penaltiesData = data
        },
        setLevelInfo(state, data: UserLevelInfo) {
            state.levelInfo = data
        },
        setKpiConfigInfo(state, data: KPIConfigInfo) {
            state.kpiConfigInfo = data
        },
        updateStreamerLevelInfoInInvites(state, {userId, data}: {userId: string, data: UserLevelInfo}) {
            const streamerToUpdate = state.invites.find(el => el.userId === userId)
            if (streamerToUpdate) {
                streamerToUpdate.level =  data.level
                streamerToUpdate.levelExchangeRate = data.exchangeRate
            }

            if (state.invitesMap.hasOwnProperty(userId)) {
                state.invitesMap[userId].level = data.level
                state.invitesMap[userId].levelExchangeRate = data.exchangeRate
            }
        },
        setBalance(state, balance) {
            state.balance = balance
        },        
        setInvitedStreamers(state, invites: InviteItem[]) {
            state.invites = invites
            state.invitesMap = invites.reduce((acc: { [k: string]: InviteItem }, item) => {
                acc[item.userId] = item

                return acc
            }, {})
        },
        setInvitationLink(state, link: InvitationLink) {
            state.invitationLink = link
        },
        setStreamersActivities(state, activities: StreamersActivities) {
            state.streamersActivities = activities
        },
        setWarningStatistic(state, statistic: LogItem[]) {
            state.warningStatistic = statistic
        },
        setCurrentStreamerEarnings(state, earnings: EarningsDataWithDate[]) {
            sortByDateField(earnings, 'date', 'asc')
            state.currentStreamerEarnings = earnings
        },
        setLoadingQueryStatus(state, queryName: string) {
            state.loadingQueriesStatus[queryName] = true
        },
        setStreamersLevelsInfo(state, levelsData: StreamersLevelData[]) {
            state.streamersLevelsInfo = levelsData
        },
        removeLoadingQueryStatus(state, queryName: string) {
            delete state.loadingQueriesStatus[queryName]
        },
    },
    actions: {
        async loadStreamersActivities({ commit }) {
            commit('setLoadingQueryStatus', 'loadStreamersActivities')
            await axiosAnalytics.get<any, StreamerActivity[]>('/v2/agency/goddessActivity')
                .then((activities: StreamerActivity[]) => {
                    const streamersActivities: StreamersActivities = activities.reduce((acc: StreamersActivities, streamerActivity) => {
                        streamerActivity.days = streamerActivity.days.map(activeStreamerActivityFields)

                        acc[streamerActivity.goddessId] = streamerActivity

                        return acc
                    }, {})
                    commit('setStreamersActivities', streamersActivities)
                })
                .finally(() => {
                    commit('removeLoadingQueryStatus', 'loadStreamersActivities')
                })
        },
        async loadInvites({ commit }) {
            await axiosCore.get<any, InviteItem[]>('/agency/goddesses/profiles')
                .then(data => commit('setInvitedStreamers', data))
        },
        async loadInvitationLink({ commit }) {
            await axiosCore.get<any, InvitationLink>('/agency/getInviteLink')
                .then(data => {
                    commit('setInvitationLink', data)
                })
        },
        async getStreamerProfile(_, userId) {
            return await axiosCore.get(`/agency/goddess/profile`, { params: { userId } })
        },
        async getStreamerFullEarnings({ commit }, userId) {            
            return await axiosCore.get<any, EarningsByDate>(`/agency/goddess/fullEarnings`, { params: { userId } })
                .then(data => {
                    const streamerFullEarnings: EarningsDataWithDate[] = Object.entries(data).map(([date, earnings]) => {
                        const [year, month, day] = date.split('_').map(item => Number(item))

                        return {
                            ...earnings,
                            date: formatDate({ date: new Date(year, month, day) }),
                        }
                    })

                    commit('setCurrentStreamerEarnings', streamerFullEarnings)
                })
        },
        async getStreamerPenaltyHistory({ commit }, userId) {
            await axiosCore.get('/agency/goddess/penaltyHistory', { params: { userId } })
                .then(data => {
                    commit('setPenaltiesData', data)
                })
                .catch(console.warn)
        },
        async getStreamerMatchingBanHistory(_, params) {            
            return await axiosCore.post(`/agency/goddess/matchingBanHistory`, { ...params })
        },
        async getStreamersWarningStatistics({ commit }) {
            await axiosAnalytics.get<any, { goddesses: LogItem[] }>('/agency/goddessWarningStatistic')
                .then(data => {
                    commit('setWarningStatistic', data.goddesses)
                })
        },
        async getStreamersLevelsInfo({ commit }) {
            await axiosAdminBack.get<any, { levels: StreamersLevelData[] }>('/v2/agency/goddessLevels')
                .then(data => {
                    commit('setStreamersLevelsInfo', data.levels)
                })
        },
        async getStreamerLevelInfo({ commit }, userId) {
            await axiosCore.get(`/agency/goddess/userLevelInfo?userId=${userId}`)
                .then(data => {
                    commit('setLevelInfo', data)
                    commit('updateStreamerLevelInfoInInvites', {userId, data} )
                })
        },
        async getStreamerBalance({ commit }, userId) {
            await axiosCore.get<any, { balance: number[] }>(`/balance?userId=${userId}`)
                .then(data => {
                    commit('setBalance', data.balance)
                })
        },
        async getKpiConfigInfo({ commit }) {
            await axiosCore.get<any, { kpiLevelsMap: any, minLevelPayOutThreshold: number }>('/goddess/kpiConfigInfo')
                .then(data => {
                    commit('setKpiConfigInfo', data)
                    console.log('setKpiConfigInfo', data)
                })
        }
    },
}