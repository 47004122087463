import { Module } from 'vuex'
import { RootState } from '@/store'
import { IObject } from '@/interfaces/common'
import { TableFilters, TableSortings } from '@/interfaces/table'
import { DateRange } from '@/interfaces/data'

export interface PageState {
    subheaderTab: string | number | null
    pageFilters: TableFilters
    pageFiltersModel: IObject
    pageSortings: TableSortings,
    dateRange: DateRange,
    selectedPeriods: DateRange[],
}

export const pageStore: Module<PageState, RootState> = {
    state: {
        subheaderTab: null,
        pageFilters: {},
        pageFiltersModel: {},
        pageSortings: {},
        dateRange: ['', ''],
        selectedPeriods: [],
    },
    getters: {
        subheaderTab: state => state.subheaderTab,
        pageFilters: state => state.pageFilters,
        pageFiltersModel: state => state.pageFiltersModel,
        pageSortings: state => state.pageSortings,
        dateRange: state => state.dateRange,
        selectedPeriods: state => state.selectedPeriods,
    },
    mutations: {
        setSubheaderTab(state, payload: string | number | null) {
			state.subheaderTab = payload
		},
        setPageFilters(state, filters: TableFilters) {
            state.pageFilters = filters
        },
        setPageFiltersModel(state, filtersModel: IObject) {
            state.pageFiltersModel = filtersModel
        },
        setPageSortings(state, sortings: TableSortings) {
            state.pageSortings = sortings
        },
        setDateRange(state, dateRange: DateRange) {
            state.dateRange = dateRange
        },
        setSelectedPeriods(state, periods: DateRange[]) {
            state.selectedPeriods = periods
        },
    },
}
